import { OnInstapageNavigateMessage, OnResizeMessage, SandboxCallbackMessage } from './types'

export function isSandboxCallbackMessage(value: unknown): value is SandboxCallbackMessage<string> {
  if (!value || typeof value !== 'object') {
    return false
  }
  const partial = value as Partial<SandboxCallbackMessage<string>>
  return (
    partial.type === 'dfhCallback' &&
    typeof partial.id === 'string' &&
    typeof partial.eventName === 'string'
  )
}

const isEventMessageWithData = (
  value: SandboxCallbackMessage<string>
): value is SandboxCallbackMessage<string> & { data: Record<string, unknown> } => {
  const valuePartial = value as SandboxCallbackMessage<string> & { data?: unknown }
  return !!value.eventName && valuePartial.data != null && typeof valuePartial.data === 'object'
}

export const isOnResizeMessage = (
  value: SandboxCallbackMessage<string>
): value is OnResizeMessage =>
  isEventMessageWithData(value) &&
  value.eventName === 'onResize' &&
  typeof value.data.width === 'number' &&
  typeof value.data.height === 'number'

export const isOnInstapageNavigateMessage = (
  value: SandboxCallbackMessage<string>
): value is OnInstapageNavigateMessage =>
  isEventMessageWithData(value) &&
  value.eventName === 'onInstapageNavigate' &&
  typeof value.data.href === 'string'
