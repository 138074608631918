import React from 'react'
import PropTypes from 'prop-types'

export const Error = ({ message }) => {
  return (
    <div className="alert alert-danger" role="alert">
      Oops... {message}
    </div>
  )
}

Error.propTypes = {
  message: PropTypes.string.isRequired,
}
