import React, { FC, useEffect } from 'react'
import type { RouteComponentProps } from '@gatsbyjs/reach-router'
import { useAuth } from '../../../lib/auth'
import { libraryUrl } from '../../../lib/library'
import { Webinar } from './Webinar'

export type WebinarRouteProps = RouteComponentProps<{ slug: string }>

export const WebinarRoute: FC<WebinarRouteProps> = ({ slug }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth()

  useEffect(() => {
    if (!slug || isAuthenticated || isLoading) {
      return
    }
    ;(async (): Promise<void> => {
      await loginWithRedirect({
        appState: {
          returnTo: `${libraryUrl}/webinar/${slug}`,
        },
      })
    })()
  }, [isAuthenticated, isLoading, loginWithRedirect, slug])

  if (!slug || isLoading) {
    return null
  }

  return <Webinar slug={slug} />
}
